import { Head } from "@inertiajs/react";

import AnimateOnScroll from "@/client/PageComponents/AnimateOnScroll";
import Feature from "@/client/PageComponents/Feature";
import Hero from "@/client/PageComponents/Hero";
import SectionLabel from "@/client/PageComponents/SectionLabel";
import Services from "@/client/PageComponents/Services";
import Tech from "@/client/PageComponents/Tech";

import laravel from "@/assets/tech/laravel.svg";
import nestjs from "@/assets/tech/nestjs.svg";
import nodejs from "@/assets/tech/nodejs.svg";
import react from "@/assets/tech/react.svg";
import wordpress from "@/assets/tech/wordpress.svg";

import Guest from "@/client/Layouts/GuestLayout";
import BlogPosts from "@/client/PageComponents/BlogPosts";
import Newsletter from "@/client/PageComponents/Newsletter";
import hosting from "@/assets/features/hosting.svg";
import programming from "@/assets/features/programming.svg";
import sass from "@/assets/features/sass.svg";

function Welcome({ posts, seo }) {
    return (
        <Guest>
            <Head title="Home" />
            <div id="home">
                <Hero
                    title={
                        <>
                            <span className="text-white ">Your Pathway to</span>
                            <br />
                            <span className="bg-gradient-to-r from-primary to-secondary bg-clip-text text-transparent">
                                Digital Excellence
                            </span>
                        </>
                    }
                    description="Welcome to The Arcane Studio, where innovative ideas transform into stunning digital realities. As a dedicated and passionate software development expert, I specialize in web hosting, web development, SaaS development, and SEO. My mission is to empower your business to thrive online by delivering tailored digital solutions that meet your unique needs."
                />
            </div>

            <div className="relative flex flex-col gap-40 py-20 md:py-40 lg:py-40 overflow-hidden">
                <div id="why-us">
                    <AnimateOnScroll>
                        <Tech
                            logos={[
                                {
                                    image: laravel,
                                    alt: "Laravel development services",
                                },
                                {
                                    image: nestjs,
                                    alt: "NestJs development services",
                                },
                                {
                                    image: nodejs,
                                    alt: "NodeJs development services",
                                },
                                {
                                    image: wordpress,
                                    alt: "Wordpress development services",
                                },
                                {
                                    image: react,
                                    alt: "React development services",
                                },
                            ]}
                        />
                    </AnimateOnScroll>

                    <AnimateOnScroll>
                        <SectionLabel
                            title="Why Choose The Arcane Studio"
                            description="At The Arcane Studio, your success is my top priority. I understand that the digital landscape is not just about having a presence; it's about making a significant impact. With a blend of expertise, passion, and a deep understanding of your unique needs and objectives, I dedicate myself to transforming your vision into reality. My commitment to excellence ensures that each project is tailored to drive your business forward, making a lasting impression in the digital world."
                        />
                    </AnimateOnScroll>

                    <AnimateOnScroll>
                        <Services />
                    </AnimateOnScroll>
                </div>

                <div id="services">
                    <div>
                        <AnimateOnScroll>
                            <SectionLabel
                                title="Our Services"
                                description="Discover a comprehensive suite of services at The Arcane Studio, including top-tier web hosting, custom SaaS development, and innovative web development solutions. Tailored to meet the needs of businesses of all sizes, my services ensure your digital presence is robust, scalable, and impactful."
                            />
                        </AnimateOnScroll>
                        <Feature
                            image={hosting}
                            title="Web Hosting"
                            alt="Web Hosting"
                            description="Experience unparalleled reliability and performance with my web hosting solutions, designed for businesses of all sizes. With state-of-the-art infrastructure, your website will always be fast, secure, and online, providing a solid foundation for your online success and peace of mind."
                        />
                    </div>
                    <Feature
                        image={sass}
                        reversed
                        title="SaaS Development"
                        alt="SaaS Development"
                        description="Scale your SaaS business with custom solutions that captivate users and drive growth. I specialize in creating scalable, user-friendly SaaS platforms tailored to meet your unique needs and objectives, ensuring your business can thrive in the competitive digital landscape."
                    />
                    <Feature
                        image={programming}
                        title="Web Development"
                        alt="Web Development"
                        description="From concept to launch, my web development services bring your digital vision to life with precision and creativity. Whether you need a stunning website or a complex web application, I tailor every project to your specific goals, ensuring a unique and engaging online presence."
                    />
                </div>

                {/* <div id="portfolio">
                    <AnimateOnScroll>
                        <MasonryGrid />
                    </AnimateOnScroll>
                </div> */}

                <div id="blog">
                    <BlogPosts posts={posts || []} label="Related Articles" />
                </div>

                <Newsletter />

                {/* <div id="testimonials">
                    <AnimateOnScroll>
                        <Testimonials />
                    </AnimateOnScroll>
                </div> */}
            </div>
        </Guest>
    );
}

export default Welcome;
