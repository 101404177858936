import { PiLightbulb, PiShield, PiTarget } from "react-icons/pi";
import AnimateOnScroll from "./AnimateOnScroll";
import Text from "./Text";

function Services() {
    const services = [
        {
            label: "Comprehensive Expertise",
            icon: <PiLightbulb color="#2FC0AD" size={72} />,
            description:
                "With extensive experience in web hosting, web development, and SEO, The Arcane Studio turns your digital vision into reality. Whether you're launching a new website or scaling a SaaS product, I have the skills to help you succeed.",
        },
        {
            label: "Custom-Tailored Solutions",
            icon: <PiTarget color="#2FC0AD" size={72} />,
            description:
                "At The Arcane Studio, I understand every business is unique. I craft bespoke digital strategies that fit your specific goals and challenges, ensuring impactful results tailored just for you.",
        },
        {
            label: "Dependable Reliability and Support",
            icon: <PiShield color="#2FC0AD" size={72} />,
            description:
                "Your peace of mind is my priority. With state-of-the-art hosting infrastructure, your website will always be fast, secure, and online. My dedicated support ensures you’re never left in the dark.",
        },
    ];

    return (
        <AnimateOnScroll>
            <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-3 md:gap-6 lg:gap-6 mx-auto w-full md:max-w-screen-2xl lg:max-w-screen-2xl mt-10 md:mt-0 lg:mt-0">
                {services.map((service, index) => (
                    <div
                        key={`service_${index}`}
                        className="flex flex-col items-center text-center py-10 px-6"
                    >
                        <div>{service.icon}</div>
                        <h3 className="text-primary font-heading my-6 text-2xl font-black">
                            {service.label}
                        </h3>
                        <Text>{service.description}</Text>
                    </div>
                ))}
            </div>
        </AnimateOnScroll>
    );
}

export default Services;
