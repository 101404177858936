import AnimateOnScroll from "./AnimateOnScroll";
import Heading from "./Heading";
import Text from "./Text";

export default function Feature({
    title,
    description,
    reversed = false,
    image,
    alt = "",
}) {
    return (
        <div
            className={`flex flex-col md:flex-row lg:flex-row flex-grow  w-full md:max-w-screen-2xl lg:max-w-screen-2xl m-auto h-[600px] justify-center items-center ${
                reversed ? "md:flex-row-reverse lg:flex-row-reverse" : ""
            }`}
        >
            <div className="flex flex-[0.5]">
                <AnimateOnScroll>
                    <img src={image} className="w-full h-auto" alt={alt} />
                </AnimateOnScroll>
            </div>
            <div className={`flex flex-[0.5] justify-center items-center text-center md:text-left lg:text-left px-3 md:px-0 lg:px-0 ${reversed ? "md:pr-20 lg:pr-20" : "md:pl-20 lg:pl-20"}`}>
                <AnimateOnScroll delay={500}>
                    <div className="flex flex-col gap-10">
                        <Heading>{title}</Heading>
                        <Text>{description}</Text>
                    </div>
                </AnimateOnScroll>
            </div>
        </div>
    );
}
