import AnimateOnScroll from "./AnimateOnScroll";
import Button from "./Button";
import PatternBackground from "./PatternBackground";
import SectionLabel from "./SectionLabel";

export default function Newsletter({
    elementId = "",
    className = "",
    ...props
}) {
    return (
        <PatternBackground>
            <AnimateOnScroll>
                <section id={elementId} className="py-16 px-6 md:px-0 lg:px-0">
                    <SectionLabel
                        title="Sign Up for Our Newsletter"
                        description="Stay informed about our latest web development insights, roadmap progress, exclusive announcements, and special discounts. Join The Arcane Studio community by signing up with your email and never miss an update!"
                    />
                    <div className="py-8 mx-auto lg:py-16">
                        <div className="mx-auto sm:text-center">
                            <form action="#">
                                <div className="items-center mx-auto mb-3 space-y-4 max-w-screen-sm sm:flex sm:space-y-0">
                                    <div className="relative w-full">
                                        <label
                                            htmlFor="news_email"
                                            className="hidden mb-2 text-sm font-medium  "
                                        >
                                            Email address
                                        </label>
                                        <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                            <svg
                                                className="w-5 h-5 text-gray-500 "
                                                fill="currentColor"
                                                viewBox="0 0 20 20"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"></path>
                                                <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z"></path>
                                            </svg>
                                        </div>
                                        <input
                                            className="pl-10 font-body shadow-sm bg-dark-grey border border-dark-grey text-primary text-md rounded-l-lg focus:ring-primary focus:border-primary block w-full p-5"
                                            placeholder="Enter your email"
                                            type="email"
                                            id="news_email"
                                            required
                                        />
                                    </div>
                                    <div>
                                        <Button>Subscribe</Button>
                                    </div>
                                </div>
                                <div className="mx-auto max-w-screen-sm text-sm text-left text-gray-500 newsletter-form-footer">
                                    We care about the protection of your data.{" "}
                                    <a
                                        href="/privacy-policy"
                                        className="font-medium text-primary-600  hover:underline"
                                    >
                                        Read our Privacy Policy
                                    </a>
                                    .
                                </div>
                            </form>
                        </div>
                    </div>
                </section>
            </AnimateOnScroll>
        </PatternBackground>
    );
}
