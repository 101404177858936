export default function Tech({ logos = [] }) {
    return (
        <section className="px-6 md:px-0 lg:px-0 mb-10 md:mb-10 lg:mb-10 flex justify-center items-center mx-auto w-full">
            <div className="grid grid-cols-5 gap-8 sm:gap-24 md:grid-cols-3 lg:grid-cols-5 w-full md:max-w-screen-2xl lg:max-w-screen-2xl">
                {logos.map(({ image, alt }, index) => (
                    <a
                        key={`tech_${index}`}
                        href="#"
                        className="flex justify-center items-center"
                    >
                        <img src={image} alt={alt} className="h-20" />
                    </a>
                ))}
            </div>
        </section>
    );
}
